export default {
  apiKey: "AIzaSyA9FI4dOdhjft5evPMrr3IYXIDxQ6FO1Qc",
  authDomain: "pitom84-2b70b.firebaseapp.com",
  databaseURL: "https://pitom84-2b70b-default-rtdb.firebaseio.com",
  projectId: "pitom84-2b70b",
  storageBucket: "pitom84-2b70b.appspot.com",
  messagingSenderId: "1030976260031",
  appId: "1:1030976260031:web:a0a0a9b8cd54327cdf830a",
  measurementId: "G-942K9QJ5VG",
};
